.ratings-module-container {
    .container {

        .ratings-container {

            div[class*="col-md-"] {
                &:first-child {
                    @media (max-width:@breakpoint-md) {
                        margin-bottom: 1rem;
                    }
                }
            }

            .rating-item-container {
                background: @tradify-blue;
                border-radius: 0.25rem;
                margin: 0 0.125rem;
                padding: 1rem;
                text-align: center;

                .rating-item {
                    overflow: hidden;
                    padding-top: 0.25rem;


                    .logo {
                        height: 50px;
                        margin-bottom: .5rem;
                        img {
                            height: 100%;
                            max-width: 160px;
                            max-height: 50px;
                        }
                    }

                    .stars {
                        height:28px;
                        img {
                            max-width: 208px;
                            max-height: 28px;
                            height: 100%;
                        }
                    }

                    .text {
                        .rating-number {
                            .font-acumin-black;
                            font-size: 2.3rem;
                            font-weight: 700;
                        }

                        .reviews-number {
                            margin-top: -0.2rem;
                            margin-bottom: 1rem;
                            .font-acumin;
                            font-size: 1.5rem;
                            line-height: 1;
                            font-weight: 100;
                        }
                    }
                }
            }
        }
    }
}