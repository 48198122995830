@import "../Website/Common/Colors.less";

@breakpoint-xs: 320px;
@breakpoint-sm: 576px;
@breakpoint-md: 768px;
@breakpoint-lg: 992px;
@breakpoint-xl: 1200px;

:root {
    --sat: env(safe-area-inset-top);
    --sar: env(safe-area-inset-right);
    --sab: env(safe-area-inset-bottom);
    --sal: env(safe-area-inset-left);
}

.font-acumin {
    font-family: acumin-pro-condensed, sans-serif;
    letter-spacing: -0.005em;
}

.font-acumin-light {
    font-family: acumin-pro-condensed, sans-serif;
    font-weight: 300;
}

.font-acumin-700 {
    font-family: acumin-pro-condensed, sans-serif;
    font-weight: 700;
}

.font-acumin-600 {
    font-family: acumin-pro-condensed, sans-serif;
    font-weight: 600;
}

.font-acumin-black {
    font-family: acumin-pro-condensed, sans-serif;
    font-weight: 800;
}

.font-roboto {
    font-family: Roboto, sans-serif;
}

body {
    font-family: Roboto, sans-serif;
}


.h1 {
    .font-size(3.75rem);
    hyphens: none;
}

.btn {
    .font-acumin;
    font-weight: 600;
    font-style: normal;
    text-align: center;
    font-size: 1rem;
    padding: 0.36em 1.44em 0.5em;
    border-radius: .25rem;
}

.btn-orange,
.btn-primary {

    .btn&,
    & {
        background-color: @screwdriver;
        color: white;

        &:hover {
            background-color: darken(@screwdriver, 2%);
        }
    }
}

.btn-blue {

    .btn&,
    & {

        background-color: @water;
        color: white;

        &:hover {
            background-color: darken(@water, 10%);
        }
    }
}

.btn.btn-lg {
    .font-size(1.5rem);
}

.btn.btn-xl {
    .font-size(2rem);
}

.container-padding {
    padding: 6em 0;

    @media (max-width:@breakpoint-lg) {
        padding: 2rem 1.0rem;
    }
}

.body-copy-padding {
    padding: 1rem 0;

    @media (max-width:@breakpoint-lg) {

        padding: 0.5rem 0;
    }

    display: block;
}

.hide-desktop {
    @media (min-width:@breakpoint-lg) {
        display: none !important;
    }
}

.hide-mobile {
    @media (max-width:@breakpoint-lg) {
        display: none !important;
    }
}

.path--site-terms,
.path--site-privacy {
    .page-content {
        a {
            color: white;
            text-decoration: underline;
        }
    }
}

.svg-to-inline {
    opacity: 0;
}

main {
    background-color: @darkish-blue;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 100px 0px fade(darken(@darkish-blue, 10%), 75%);
    color: white;

    &:before {
        content: " ";
        background-color: #004b8c;
        background-image: linear-gradient(to bottom, @water, @darkish-blue);
        height: 2300px;
        width: 100%;
        position: absolute;
        display: block;
        box-sizing: border-box;
        .exclude-hero-component & {
            background: @water;
        }
    }

    h1 {
        line-height: 1;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        hyphens: none;
        .font-acumin;

        .ua-ie & {
            font-weight: 300;
        }
    }

    p {
        font-family: Roboto, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.36;
        letter-spacing: normal;
        hyphens: none;
    }

    ul {
        padding-inline-start: 1rem;

        li {
            font-weight: 300;
        }
    }

    #hero-container {
        position: relative;
    }

    .page-content {
        & > div {
            .container-padding;
            position: relative;

            &:after {
                content: " ";
                width: 100%;
                height: 1px;
                position: absolute;
                top: 0;
            }

            &:nth-child(odd) {
                background: fade(@darkish-blue, 25%);
            }
        }

        &>span>div>span,
        &>span>div>.hs_cos_wrapper_widget,
        & .page-content-item {
            .container-padding;
            padding: 3.5em 0;
            position: relative;
            display: block;

            &.white-container {
                background: white;
                color: @coal;
            }

            &:after {
                content: " ";
                width: 100%;
                height: 1px;
                position: absolute;
                top: 0;
                background: #81c5db2a;
            }

            &>* {
                width: 100%;
                padding-right: 15px;
                padding-left: 15px;
                margin-right: auto;
                margin-left: auto;

                @media (min-width: 576px) {
                    max-width: 540px;
                }

                @media (min-width: 768px) {
                    max-width: 720px;
                }

                @media (min-width: 992px) {
                    max-width: 960px;
                }

                @media (min-width: 1200px) {
                    max-width: 1140px;
                }
            }
        }

        &>span>div:nth-child(odd) .page-content-item {
            background-color: fade(@darkish-blue, 25%);
        }
    }
}


.lazy {
    opacity: 0;
    transition: opacity 1s;

    .ua-safari &,
    .ua-ios & {
        opacity: 1;
    }

    &.loaded {
        opacity: 1;
    }
}

.photo-border {
    border: .5rem solid white;
    border-radius: .25rem;
    box-sizing: border-box;

    @media (max-width:@breakpoint-lg) {
        .rfs(.4rem, border-width);
    }
}

.panel-heading {
    .font-size(3.25rem);
    hyphens: none;

    @media (min-width:@breakpoint-lg) {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: stretch;
        align-items: flex-start;
    }

    &>span {
        @media (min-width:@breakpoint-lg) {
            display: block;
        }

    }

    .ua-ie &,
    .ua-edge & {
        display: block;
    }
}

.text-wrap-span {
    span {
        @media (min-width:@breakpoint-md) {
            clear: both;
            display: block;
        }
    }
}

.valign-center {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: flex-start;
}

.template-document {
    .page-content {
        a {
            color: white;
            text-decoration: underline;
        }
    }
}


.trial-signup-cta-container {
    .trial-signup-cta {
        text-align: center;

        h1 {
            .font-acumin-black;
            .font-size(5rem);
            margin: 0 auto;
            margin-bottom: 0.5em;

            @media (max-width:@breakpoint-lg),
            (max-height:@breakpoint-md) {
                font-size: 2.5rem;
            }
        }

        h2 {
            .font-acumin-black;
            .font-size(5rem);
            margin: 0 auto;
            margin-bottom: 0.5em;

            @media (max-width:@breakpoint-lg),
            (max-height:@breakpoint-md) {
                font-size: 2.5rem;
            }
        }

        p {
            .font-size(2rem);
            margin-bottom: 1.5em;
            font-weight: 100;
        }

        .tf-email-input-group {
            max-width: 400px;
            margin: 0 auto;

            .error-list {
                li {
                    text-align: left;
                }
            }
        }

        @media (max-height:550px) {
            font-size: 8px;

            h1 {
                font-size: 2rem;
                max-width: 100%;
            }

            p {
                max-width: 90%;
                margin: 0 auto 1rem;
            }
        }
    }
}