.tf-email-input-group {
    font-size: 16px;
    @button-offset: 0.5em;
    @button-height: 2.5em;
    @button-width: 4em;
    position: relative;
    box-sizing: border-box;

    @media (max-width:@breakpoint-lg) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
       
    }

    input[type=email],
    input[type=text] {
        height: @button-height + @button-offset*2 ;
        padding: (@button-offset) 1em;
        padding-left: 1.2em;
        padding-right: @button-width + @button-offset*2;
    }

    &.country-fr input[type=email] {
        @media (max-width:@breakpoint-lg) {
            
                width: 100%;
                align-self: center;
                margin-bottom: 1rem;
                text-align: center;
                padding-left: 0;
                padding-right:0;          
        }
    }

    .btn {
        width: @button-width;
        height: @button-height;
        position: absolute;
        top: @button-offset;
        right: @button-offset;
        padding: 0.375em 0.75em;

        .trial-signup-form & {
            font-size:inherit;
        }
    }

    &.country-fr .btn {
        width: 10em;
        height: @button-height;
        position: absolute;
        top: @button-offset;
        right: @button-offset;
        padding: 0.375em 0.75em;

        .trial-signup-form & {
            font-size:inherit;
        }
        @media (max-width:@breakpoint-lg) {
            position:relative;
            width: 100%;
            align-self: center;
            margin-bottom: 1rem;
            text-align: center; 
            margin-left: 1rem;   
        }
    }
    
    .error-list {
        margin-top: 0.5rem;
        display: none;

        &.show-errors {
            display: block;
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                margin-bottom: 0.5rem;
            }
        }

        .invalid-feedback {
            display: block;
            background: @tradify-red;
            color: white;
            padding: 0.5em 0.7em;
            border-radius: 2px;
        }
    }

}