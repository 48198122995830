.tick-module-container {

    h1 {
        margin-top: 3px;
        .font-size(3.25rem);
        hyphens: none;
    }

    .tick-container {
        margin-top: 1.5rem;

        .tick-item {
            font-size: 2.0rem;
            .font-acumin;
            font-weight: bold;
            line-height: 1;
            width: 100%;
            display: inline-block;
            position: relative;
            @icon-size: 2.2rem;
            padding-left: @icon-size + 1rem;

            &:before {
                content: "";
                width: @icon-size;
                height: @icon-size;
                margin-top: 0.15rem;
                position: absolute;
                left: 0;
                background-image: url('https://cdn2.hubspot.net/hubfs/2929749/site/static/images/check-badge.svg');
                background-size: 100%;
            }
        }
    }
}