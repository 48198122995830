#IndustryPageFeatureWidget {
    .company-size-selecter {
        margin-bottom: 2rem;

        @media (max-width:763px) {
            margin-bottom: 0rem;
        }

        h2 {
            font-size: 1.666rem;
            font-family: Roboto;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.27;
            letter-spacing: normal;
            margin-bottom: 1rem;
        }

        .btn-group {
            width: 100%;
            max-width: 500px;

            @media (max-width: @breakpoint-sm) {
                max-width: 100vw;
            }

            .btn {
                flex: 1 1 0;
                padding: 0.9rem;
                background: #006c97;
                @border-width: 2px;
                border: solid @border-width #0b3540;
                font-family: Roboto;
                font-size: 1rem;
                font-weight: 400;
                color: white;
                
                @border-radius: 0.30rem;
                position: relative;
                margin-right: -(@border-width);
                
                &:first-child {
                    border-top-left-radius: @border-radius;
                    border-bottom-left-radius: @border-radius;
                }
                
                &:last-child {
                    border-top-right-radius: @border-radius;
                    border-bottom-right-radius: @border-radius;
                    
                }
                
                &.active {
                    background: @tradify-blue;
                    font-weight: 700;
                    border-color: #9dd9e9;
                    border-radius: @border-radius;
                    z-index: 2;
                }
            }
        }
    }


    h1 {
        font-size: 3.8rem;
        margin: 2rem 0 2rem;

        @media (max-width:763px) {
            font-size: 3rem;
            margin-bottom: 2.5rem;
        }

    }
    
    h2 {
        font-size: 3.8rem;
        margin: 2rem 0 2rem;

        @media (max-width:763px) {
            font-size: 3rem;
            margin-bottom: 2.5rem;
        }

    }

    .feature-list-container {

        max-width: 1110px;

        .features-list {
            max-width: 1110px;
            @gap: 20px;
            display: block;

            @media (max-width:573px) {
                width: 100% !important;
            }

            .feature-card {
                background: @tradify-blue;
                font-size: 1rem;
                @corner-size: 28px;
                clip-path: polygon(100% 0, 100% calc(100% - @corner-size), calc(100% - @corner-size) 100%, 0 100%, 0 0);
                width: 356.6666666666667px;

                @media (max-width:1200px) {
                    width: 455px;
                }

                @media (max-width:991px) {
                    width: 690px;
                    min-height: 0;
                    max-height: 100vh;

                }

                @media (max-width:763px) {
                    width: 510px;
                }

                @media (max-width:573px) {
                    position: relative !important;
                    top: 0 !important;
                    left: 0 !important;
                    width: 100%;
                }

                margin: 0;

                & {
                    // Animation
                    display: none;
                    max-height: 0;
                    min-height: 300px;
                    overflow: hidden;
                    transition: all .2s ease-in-out;
                    opacity: 0;
                    margin-bottom: 0;
                    padding: 0;

                    &.fadeIn {
                        display: block;
                        opacity: 1;
                        margin-bottom: @gap;
                        padding: 1.94rem 1.03rem;
                    }
                }

                .feature-card-block {
                    .feature-card-header {
                        display: flex;

                        .feature-card-image {
                            max-width: 80px;
                            width: 100%;
                            flex: 0 1 auto;

                            .img-fluid {
                                top: -7px;
                                position: relative;
                            }
                        }

                        .feature-card-title {
                            flex: 1 1 auto;
                            font-size: 1.222rem;
                            font-family: Roboto;
                            font-weight: 600;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.23;
                            letter-spacing: normal;
                        }
                    }

                    .feature-card-text {
                        font-family: Roboto;
                        font-weight: 300;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.39;
                        letter-spacing: normal;
                        color: #fefefe;
                        
                    }
                }

                &.feature-card-cta {
                    background: #07455b;
                    height: 210px;

                    .feature-card-header {

                        .feature-card-title {
                            font-family: Roboto;
                            font-weight: 700;
                            font-size: 1.1rem;
                            margin-bottom: 1.5rem;
                        }

                        .feature-card-image {
                            width: 50px;
                            margin-left: 1rem;

                            img.img-fluid {
                                top: 0.25rem;
                                @size: 40px;
                                width: @size;
                                height: @size;
                                display: block;
                            }
                        }
                    }

                    .feature-card-text {
                        .btn {
                            background: @tradify-orange;
                            color: white;
                            font-size: 1.25rem;
                            border-radius: .2rem;
                            margin-right: 2rem;
                        }

                        a {
                            .font-acumin;
                            // .font-acumin-black;
                            font-weight: bold;
                            color: white;
                            text-decoration: none;
                            font-size: 1.25rem;

                        }
                    }

                }
            }
        }

    }
}