@import (reference) "../base.less";

.email-prefs-secondary-button() {
    cursor: pointer;
    margin-left: 5px;
    text-decoration: none;
    ;
}

.tradify-email-preferences {
    .email-preferences-title-1() {
        font-size: 28.8px;
        font-weight: 100;
        margin: 0.5em 0;
        margin-bottom: 0.5em;
    }

    .email-preferences-title-2() {
        font-size: 35.2px;
        font-weight: 100;
        margin: 0.5em 0;
        margin-bottom: 0.5em;
        color: @tradify-blue;
        line-height: 1.3;
        width: 1000px;
        max-width: 100vw;
        color: white;
    }

    .email-preferences-header() {
        margin-top: 0px;
        max-width: 600px;

        p {
            font-size: 16px;
            font-weight: 300;
        }
    }

    .container {

        form#email-prefs-form,
        & {
            .page-header {
                .email-preferences-header();

                h1 {
                    .email-preferences-title-1();
                }

                h2 {
                    .email-preferences-title-2();
                }
            }

            .email-prefs {
                .manage-subscriptions {
                    margin-top: 30px;
                    margin-bottom: 0;
                    .email-prefs-secondary-button();
                }
            }
        }
    }

    .email-preferences-header {
        .email-preferences-header();

        h3 {
            margin-bottom: 0;
            .email-preferences-title-1();
        }

        h2 {
            .email-preferences-title-2();
        }

        .email-not-found {
            p {
                margin: 1em 0 0;
                font-size: 14px;
            }
        }
    }
}

#legacy-email-preferences-form {

    ul {
        padding: 0;
    }

    width: 100%;
    max-width: 600px;

    .hs_email {
        &>label {
            display: none;
        }

        .hs-error-msgs {
            background: fade(red, 10%);
            border-radius: 2px;
            padding: 1em;
        }

        display:block !important;
    }

    .hs_email_subscriptions {
        &>label {
            display: none;
        }

        h2 {
            font-size: 22px;
        }

        li {
            margin: 10px 0;
            display: block;
            overflow: hidden;

            h2 {
                margin: 1em 0 1em;
            }

            label {
                cursor: pointer;
                float: left;
                width: 100%;
                background: hsl(199, 100%, 25%);
                border-radius: 2px;
                margin-bottom: 1px;
                padding: 20px;
                box-sizing: border-box;

                span {
                    display: block;
                    width: 75%;
                    float: left;
                    font-size: 16px;
                    font-weight: bold;

                    .description {
                        font-size: 12px; // padding-left:2em;
                        font-weight: 300;
                    }
                }

                input {
                    margin-top: 10px;
                    float: left;
                    width: 25%;
                    max-width: 50px;
                    display: block;
                }
            }
        }
    }

    li.hs-form-checkbox {
        a {
            color: @tradify-blue;
            text-decoration: underline;
        }

        display: none;

        &.tradify-blog-updates {
            display: block;
        }

        &.tradify-job-notifications {
            label {
                cursor: default;
            }

            input {
                opacity: 0;
            }
        }

        .lead& {
            &.tradify-offers-updates {
                display: block;
            }
        }

        .user& {

            &.tradify-guides-tips-and-tricks,
            &.product-news-and-updates,
            &.important-service-announcements,
            &.tradify-job-notifications,
            &.sales-team {
                display: block;
            }
        }

        .partner-lead& {
            &.tradify-partner-offers {
                display: block;
            }
        }

        .partner& {
            &.tradify-partner-news-updates {
                display: block;
            }
        }

        .dev& {
            display: block;
        }
    }

    .hs_submit {
        .actions {
            a.unselect-all {
                .email-prefs-secondary-button();

            }
        }
    }
}