@import (reference) "../base.less";

.email-subscriptions-website_v2020 {
    #hero-container {
        .content-text {
            padding: 7em 0 5em;
            .hero-heading {}
        }
    }

    #email-prefs-form>div.page-header>h1 {
        display: none;
    }

    #content>div.item {
        padding: 2em 2em 1em;
        background: hsl(199, 100%, 25%);
        margin: 1em 0 1em;
        border-radius: 5px
    }
}

.email-preferences-container {
    color: white;
    font-size: 16px;
    .widget-type-email_subscriptions {
        .hs_cos_wrapper_type_email_subscriptions {
            #email-prefs-form {
                .page-header {
                    h1 {
                        margin-top: 200px;
                    }
                    h2 {
                        margin: 0;
                        color: white;
                    }
                }
                .subscribe-options {
                    margin-top: 2em;
                }
            }
        }
    }
}