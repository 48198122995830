.review-widget {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .reviews-content {
        order: 0;
        flex: 1 1 auto;
        width: 90%;
        transition: height 0.2s ease-in-out;
        padding-right: 1em;
        position: relative;

        .reviews {
            .review {
                opacity: 0;
                top: 0;
                transition: opacity 0.2s, transform 0.2s;
                transform: translateY(15%);
                position: absolute;

                &.selected {
                    opacity: 1;
                    transform: translateY(0%);
                    position: relative;
                    transition: opacity 0.05s, transform 0.2s;
                }

                .display-review();
            }
        }
    }

    .review-stars {
        margin: 0 0 1rem;

        img {
            width: 1.2rem;
        }
    }

    .review-nav {
        order: 0;
        flex: 0 1 auto;

        .review-nav-item {
            margin-bottom: 1em;

            &:last-of-type {
                margin-bottom: 0;
            }

            .review-button {
                .review-nav-button();
            }
        }
    }
}

.review-nav-button(@size: 44px) {
    cursor: pointer;
    border: none;
    background: none;
    color: white;
    background: @coal;
    border-radius: 50%;
    width: @size;
    height: @size;
    position: relative;
    .font-acumin;

    &.selected {
        background: white;
        color: @coal;
    }

    span {
        .font-acumin-black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        font-size: @size * 0.613;
        height: 0.7em;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.5;
        letter-spacing: normal;
        text-align: center;
    }

    @media (max-width: @breakpoint-sm) {
        font-size: 0.20 * @size;
        width: 4.5em;
        height: 4.5em;
        top: 0.5em;

    }

    @media (max-width: @breakpoint-lg) {
        span {
            height: 0.6em;
        }
    }
}

.display-review() {
    .review-text {
        font-family: Roboto, sans-serif;
        .font-size(1.875rem);
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.33;
        letter-spacing: normal;
        margin-bottom: 0.5em;
        text-indent: -0.3em;

        &:before {
            content: "“"
        }

        &:after {
            content: "”"
        }
    }

    .reviewer {
        @size: 40px;
        height: @size;
        .d-flex;
        .align-items-center;

        .name {
            strong {
                font-weight: bold;
                .font-acumin;

            }

            p {
                margin: 0;
            }
        }

        .image {
            margin-right: 0.5em;
            width: @size*1.1;
            height: @size*1.1;
            overflow: hidden;
            border-radius: 50%;
            border-radius: 100%;

            img {
                height: 100%;
                width: 100%;
            }
        }

    }
}