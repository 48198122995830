.form-base {
    .form-group {
        position: relative;
        margin-bottom: 0.5rem;

        label {
            display: block;
            opacity: 0;
            max-height: 0;
            margin-top: 0;
            transition: opacity 0.6s, max-height 0.6s, margin-top 0.6s;

            overflow: hidden;
            font-size: 0.8rem;
            margin-bottom: 0.25em;
            font-weight: 100;
        }

        &.has-value {
            label {
                opacity: 1;
                max-height: 100%;
                margin-top: 1em;
            }
        }

        input,
        textarea {
            padding: 1.5em 1.1em;
            border-radius: 3px;
            font-size: 1rem;
            border-color: #495057;

            &::placeholder {
                margin-top: 0;
            }
        }

        select {
            -moz-appearance: none;
            -webkit-appearance: nonoe;
            -ms-progress-appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';

            width: 100%;
            appearance: none;
            background: none;
            background-color: white;
            padding: .5em .9em;
            height: 3rem;
            border-radius: 3px;
            font-size: 1rem;
            color: #6c757d;
            border-color: #495057;

            &::-ms-expand {
                display: none;
            }

        }

        input {
            .ua-ie & {
                height: 60px;
                padding: 0.5em 0.8em;
            }
        }

        .form-control {

            &:valid,
            &:invalid {
                border-color: inherit;
            }
        }

        .valid-feedback {
            border: inherit;
        }

        .invalid-feedback {
            background: @tradify-red;
            color: white;
            padding: 0.5em 0.7em;
            border-radius: 2px;
        }


    }

    .row.has-value {
        label {
            max-height: 100%;
            margin-top: 1em;
        }
    }

    .btn {
        .font-size(1.1rem);
        float: right;
        background: @coal;
        border-color: @coal;
        margin-top: 1em;
        cursor: pointer;
    }

    .show-errors {
        .error-list {

            &,
            &>div,
            .invalid-feedback {

                display: block !important;
            }
        }
    }

    .consent-options {
        label {
            max-height: inherit;
            opacity: 1;
            font-size: 1rem;
        }
    }
}