.text-slider-container {
    font-size: 16px;

    h1 {
        .h1;
        .font-acumin;
        margin-bottom: 1.5rem;
    }

    h2 {
        .h1;
        .font-acumin;
        margin-bottom: 1.5rem;
    }
    
    @media (max-width:@breakpoint-sm) {
        // height: 2000px;
    }

    .tabs {
        .tab {
            cursor: pointer;
            
            &.selected {
                .tab-number {
                    background: white;
                    color: @coal;
                }

                .tab-title {
                    color: white;


                }
            }

            .tab-number {
                @size: 2.75rem;
                display: inline-block;
                .font-acumin;

                span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    @size: 1.5em;
                    .font-size(1.7rem);
                    height: 0.7em;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 0.5;
                    letter-spacing: normal;
                    text-align: center;
                }

                border: none;
                background: none;
                color: white;
                background: @coal;
                border-radius: 50%;
                width: @size;
                height: @size;
                position: relative;
                top:0.8em;
                
                @media (max-width: @breakpoint-sm) {
                    .font-size(0.5rem);
                    width: @size*0.5;
                    height: @size*0.5;
                    top:0.5em;
                }
            }

            .tab-title {
                .font-size(1.875rem);

                @media (min-width: @breakpoint-sm) and (max-width: @breakpoint-lg) {
                    .font-size(1.5rem);
                }

                @media (max-width: @breakpoint-sm) {
                    .font-size(1.5rem);
                }

                line-height: 2;
                display: inline-block;
                margin-left: 0.5em;
                font-family: Roboto, sans-serif;
                font-weight: 300;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.31;
                letter-spacing: normal;
                color: @coal;

            }
            
            .tab-text {
                @media (min-width: @breakpoint-md) {
                    display:none;
                }
                
                height:100%;
                max-height:0em;
                overflow:hidden;
                transition: max-height .2s ease-in-out;
                &.expanded {
                    transition: max-height .4s ease-in-out;
                    max-height: 40vh;
                    transition-delay: .1s;
                    margin-left: 2.375rem;
                }
            }
            
            @media (min-width: @breakpoint-md) {
                display: flex;
                
                .tab-number {
                    flex: 0 0 auto;
                }
                
                .tab-title {
                    flex: 1 1 auto;
                    margin-top: .8rem;
                }
            }
            
        }
    }

    .items {
        overflow: hidden;
        height:100%;
        transition: 'max-height' 0.5s;
        position: relative;
        margin-top: 0.6em;
        @transition-distance: 20%;
        @media (max-width:@breakpoint-sm) {
            display:none;
        }
        @media (max-width:@breakpoint-md) {
            display:none;
        }
        
        .item {
            position: absolute;
            top: -(@transition-distance);
            opacity: 0;
            transition: top .5s, opacity 0.2s;
            transform: translateY(-50%);
            
            &.selected {
                top: 50%;
                
                opacity: 1;
            }
            
            
            &.reset {
                transition: top 0s, opacity 0s;
                top: (@transition-distance);
                opacity: 0;
            }
            
            .item-text {
                font-family: Roboto, sans-serif;
                .font-size(2rem);
                font-weight: 300;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.33;
                letter-spacing: normal;
            }
        }
    }
}