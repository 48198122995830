.quote-module-container {

    .left-heading {
        margin-top: 3px;
        .font-size(3.25rem);
        hyphens: none;
    }

    .quote-container {
        .quote-text {
            font-size: 1.5rem;
            line-height: 1.33;
            margin-bottom: 0.5em;
            text-indent: -0.3em;

        }

        .quote-caption {
            @size: 40px;
            height: @size;
            margin-top: 25px;
            font-weight: bold;

            .name {
                font-size: 1.20rem;
                line-height: 1;

                strong {
                    font-size: 1.2em;
                    .font-acumin;
                    font-weight: bold;
                }

                p {
                    margin: 0;
                }
            }

            .image {
                display: none;
                margin-right: 0.5em;
                width: @size*1.1;
                height: @size*1.1;
                overflow: hidden;
                border-radius: 50%;
                border-radius: 100%;

                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}