.accordian-module-container {
    font-size: 1rem;

    .container {
        h2 {
            text-align: center;
            font-size: 3.5em;
            font-weight: 100;
            .font-acumin;
            margin-bottom: 0.7em;
        }

        .accordian-container {
            .col-md-12 {
                .accordian-item-container {
                    border-radius: 0.75em;
                    overflow: hidden;

                    .accordian-item {
                        margin: 0.25em 0;

                        .title {
                            background: #0086b6;
                            cursor: pointer;
                            padding: 1.7em 2.1em;
                            position: relative;

                            .fa-angle-down {
                                display: block;
                                position: absolute;
                                right: 2em;
                                top: 50%;
                                transform: translateY(-50%);
                            }

                            h3.title-text {
                                font-family: Roboto, sans-serif;
                                font-size: 1.2em;
                                font-weight: 100;
                                line-height: 1.5;
                                margin-bottom: 0em;
                            }
                        }

                        .text-container {
                            display: block;
                            background: #0086b67e;
                            max-height: 0;
                            transition: max-height 0.2s ease-in-out;
                            overflow: hidden;

                            p.accordian-text {
                                margin: 1.7em 2.1em;
                                a {
                                    color: white;
                                    text-decoration: underline;
                                }
                            }
                            p {
                                margin: 1.7em 2.1em;
                                a {
                                    color: white;
                                    text-decoration: underline;
                                }
                            }
                        }

                        &.visible {
                            .text-container {
                                max-height: 150vh;
                            }
                        }
                    }
                }
            }
        }
    }
}

.cro-accordian-module-container {
    body main .page-content > span .page-content-item& {
        background-color: #f8f8f8;
        padding: 3.5em 0;
    }

    h1,
    h2,
    h3,
    p {
        line-height: 120%;
    }
    h1,
    h2 {
        font-family: acumin-pro-condensed, sans-serif;
        font-weight: 700;
        font-size: 3rem;
        color: #004b8c;
        margin-bottom: 1rem;
    }
    h3 {
        font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-weight: 500;
        font-size: 1.5rem;
        letter-spacing: -0.02em;
        color: #009ec7;
        margin-bottom: 1rem;
    }

    p {
        font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-weight: 300;
        font-size: 1rem;
        color: #263238;
        margin-bottom: 2rem;
    }

    .section-divider {
        border: 1px solid #009ec7;
        margin-bottom: 1rem;
    }

    .cro-accordian-header-container {
        text-align: center;
    }

    .cro-accordian-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: wrap;

        .cro-accordian-right-item-container {
            width: calc(50% - 1rem);
            float: right;
            @media (max-width: @breakpoint-lg) {
                width: 100%;
            }
        }
        .cro-accordian-left-item-container {
            width: calc(50% - 1rem);
            margin-right: 2rem;
            @media (max-width: @breakpoint-lg) {
                width: 100%;
                margin: 0;
            }
        }

        .cro-accordian-item-container {
            padding-right: 0;
            padding-left: 0;
            border-bottom: 2px solid #c5c5c5;

            .cro-title {
                cursor: pointer;
                padding: 1rem 0;
                display: flex;

                .cro-question {
                    width: 95%;
                    padding-right: 1rem;

                    h3 {
                        font-family: Roboto, sans-serif;
                        font-size: 1.2em;
                        font-weight: 400;
                        color: #263238;
                        line-height: 1.5;
                        margin-bottom: 0em;
                    }
                }
            }

            .cro-text-container {
                display: block;
                width: 100%;
                max-height: 0;
                transition: max-height 0.2s ease-in-out;
                overflow: hidden;
            }
        }
        .cro-accordian-item {
            &.visible {
                .cro-text-container {
                    margin-top: -2rem;
                    max-height: 150vh;
                    transition: max-height 0.2s ease-in-out;
                }
                .arrow {
                    margin-top: -0.5rem;
                    align-self: center;
                    border: 12px solid transparent;
                    border-bottom-color: #97a3aa;
                }
            }
        }

        .arrow {
            margin-top: 0.5rem;
            float: right;
            align-self: center;
            width: 24px;
            height: 12px;
            border: 12px solid transparent;
            border-top-color: #97a3aa;
        }
    }
}

.new-accordian-module-container {
    body main .page-content > span .page-content-item& {
        background-color: #f8f8f8;
        padding: 1.5em 0 2.5em 0;
    }

    h1,
    h2,
    h3,
    p {
        line-height: 120%;
    }
    h1,
    h2 {
        font-family: acumin-pro-condensed, sans-serif;
        font-weight: 700;
        font-size: 3rem;
        color: #004b8c;
        margin-bottom: 1rem;
    }
    h3 {
        font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-weight: 500;
        font-size: 1.5rem;
        letter-spacing: -0.02em;
        color: #009ec7;
        margin-bottom: 1rem;
    }

    p {
        font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
        font-weight: 300;
        font-size: 1rem;
        color: #263238;
        margin-bottom: 2rem;
    }

    .section-divider {
        border: 1px solid #009ec7;
        margin-bottom: 1rem;
    }

    .accordian-header-container {
        text-align: center;
    }

    .accordian-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-flow: wrap;

        .accordian-right-item-container {
            width: calc(50% - 1rem);
            float: right;
            @media (max-width: @breakpoint-lg) {
                width: 100%;
            }
        }
        .accordian-left-item-container {
            width: calc(50% - 1rem);
            margin-right: 2rem;
            @media (max-width: @breakpoint-lg) {
                width: 100%;
                margin: 0;
            }
        }

        .accordian-item-container {
            padding-right: 0;
            padding-left: 0;
            border-bottom: 2px solid #c5c5c5;

            .title {
                cursor: pointer;
                padding: 1rem 0;
                display: flex;

                .question {
                    width: 95%;
                    padding-right: 1rem;

                    h3 {
                        font-family: Roboto, sans-serif;
                        font-size: 1.2em;
                        font-weight: 400;
                        color: #263238;
                        line-height: 1.5;
                        margin-bottom: 0em;
                    }
                }
            }

            .text-container {
                display: block;
                width: 100%;
                max-height: 0;
                transition: max-height 0.2s ease-in-out;
                overflow: hidden;
            }
        }
        .accordian-item {
            &.visible {
                .text-container {
                    margin-top: -2rem;
                    max-height: 150vh;
                    transition: max-height 0.2s ease-in-out;
                }
                .arrow {
                    margin-top: -0.5rem;
                    align-self: center;
                    border: 12px solid transparent;
                    border-bottom-color: #97a3aa;
                }
            }
        }

        .arrow {
            margin-top: 0.5rem;
            float: right;
            align-self: center;
            width: 24px;
            height: 12px;
            border: 12px solid transparent;
            border-top-color: #97a3aa;
        }
    }
}
