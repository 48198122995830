.hero-content {
    .content-text {
        padding-top: 7rem;
        padding-bottom: 7rem;

        @media (max-width:@breakpoint-lg) {
            padding-top: 1rem;
            padding-bottom: 1.5rem;
        }

        .btn {
            font-size: 1.25rem;
        }

        .tf-email-input-group {
            .btn {
                font-size: 1rem;
            }
        }
    }
}

#hero-container {
    overflow: hidden;

    @media (max-width:@breakpoint-lg) {
        padding: 1rem 1rem;
    }

    .content-text {
        hyphens: none;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: stretch;
        align-items: flex-start;

        .ua-ie &,
        .ua-edge & {
            display: block;
            position: relative;
            min-height: 415px;

            &>* {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

        }

        h1,
        h2,
        h3,
        h4 {
            .font-acumin;
            letter-spacing: 0.01em;
            line-height: 1;
            font-weight: 300;
            hyphens: none;
        }

        h1 {
            margin-bottom: 0.8em;
        }

        .hero-heading {
            .panel-heading;
            height: unset;
            .font-size(3.75rem);
            max-width: 100%;
        }

        p {
            .font-size(1.6rem);
        }

        .small-text{
            .font-size(0.8rem);
        }

    }

    .feature-image {
        display: flex;
        align-items: center;
        height: 100%;

        img {
            width: 100%;

        }
    }
}